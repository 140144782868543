var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[(_vm.gameInstanceCount)?_c('v-data-table',{staticClass:"flex",attrs:{"footer-props":_vm.itemsPerPageOptions,"headers":_vm.headers,"items":_vm.gameInstances,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"sort-by":_vm.sort,"sort-desc":_vm.desc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"disabled":_vm.loading,"hide-details":"","label":_vm.labels.search,"prepend-icon":'$search',"single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.provider",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.csha",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.backend_version.csha ? 'primary' : 'warning',"dark":"","label":"","outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.copyToClipboard(item.backend_version.csha)}}},[(!item.backend_version.csha)?_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('$warning')}}):_vm._e(),_c('span',{ref:"textToCopy",class:_vm.expandCsha ? '' : 'text-truncate'},[_vm._v(" "+_vm._s(item.backend_version.csha || _vm.labels.unknown)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-btn',{staticClass:"flat-btn",attrs:{"color":"primary","block":"","plain":"","to":{
          name: 'game-edit',
          params: {
            image: item.image,
          },
        }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.consolidated",fn:function(ref){
        var value = ref.value;
return [_c('v-chip',{attrs:{"dark":"","color":value ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(value ? 'ready' : 'pending')+" ")])]}},{key:"item.backend_version.gs_logiclib_version",fn:function(ref){
        var value = ref.value;
return [(_vm.isCompatible(value))?_c('span',[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.canCreatePromotions,"text":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.openPromotionsDialog(item)}}},[_vm._v(" "+_vm._s(_vm.labels.promote)+" ")]),(_vm.showLauncher(item))?_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary","disabled":!item.consolidated,"href":_vm.getLaunchUrl(item),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.labels.play)+" ")]):_vm._e(),_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary","to":{
          name: 'game-logs',
          params: {
            envName: _vm.environment.name,
            image: item.image,
            version: item.game_backend_version,
          },
        }}},[_vm._v(" "+_vm._s(_vm.labels.logs)+" ")]),_c('v-btn',{attrs:{"text":"","outlined":"","color":"primary","disabled":!item.consolidated},on:{"click":function($event){return _vm.showEnvironmentVariables(item)}}},[_vm._v(" "+_vm._s(_vm.labels.details)+" ")])]}}],null,false,1861898717)}):_c('v-skeleton-loader',{staticClass:"flex",attrs:{"loading":"","type":"table"}}),(_vm.anyWorkloadDetail)?_c('details-dialog',{attrs:{"dialog-items":_vm.workloadDetails,"name":_vm.name,"show":_vm.showDetailsDialog,"version":_vm.version},on:{"close":function($event){_vm.showDetailsDialog = false}}}):_vm._e(),_c('promotion-dialog',{attrs:{"show":_vm.showPromotionsDialog,"game-to-be-promoted":_vm.gameToBePromoted},on:{"close":function($event){_vm.showPromotionsDialog = false},"promote":_vm.promote}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }