<template>
  <v-row no-gutters>
    <v-data-table
      v-if="gameInstanceCount"
      :footer-props="itemsPerPageOptions"
      :headers="headers"
      :items="gameInstances"
      :items-per-page="itemsPerPage"
      :page="page"
      :search="search"
      :sort-by="sort"
      :sort-desc="desc"
      class="flex"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          :disabled="loading"
          hide-details
          :label="labels.search"
          :prepend-icon="'$search'"
          single-line
        />
      </template>
      <template v-slot:item.provider="{ value }">
        <v-chip color="primary">
          {{ value }}
        </v-chip>
      </template>
      <template v-slot:item.csha="{ item }">
        <v-chip
          @click.prevent="copyToClipboard(item.backend_version.csha)"
          :color="item.backend_version.csha ? 'primary' : 'warning'"
          dark label outlined
        >
          <v-icon left v-if="!item.backend_version.csha" v-text="'$warning'" />
          <span :class="expandCsha ? '' : 'text-truncate'" ref="textToCopy">
            {{ item.backend_version.csha || labels.unknown }}
          </span>
        </v-chip>
      </template>
      <template v-slot:item.name="{ item, value }">
        <v-btn
          class="flat-btn"
          color="primary"
          block plain
           :to="{
            name: 'game-edit',
            params: {
              image: item.image,
            },
          }"
        >
          {{ value }}
        </v-btn>
      </template>

      <template v-slot:item.consolidated="{ value }">
        <v-chip dark :color="value ? 'success' : 'warning'">
          {{ value ? 'ready' : 'pending' }}
        </v-chip>
      </template>

      <template v-slot:item.backend_version.gs_logiclib_version="{ value }">
        <span v-if="isCompatible(value)">{{ value }}</span>
        <span class="warning--text" v-else>{{ value }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          :disabled="!canCreatePromotions"
          text outlined
          color="primary"
          @click="openPromotionsDialog(item)"
        >
          {{ labels.promote }}
        </v-btn>
        <v-btn
          v-if="showLauncher(item)"
          text outlined
          color="primary"
          :disabled="!item.consolidated"
          :href="getLaunchUrl(item)"
          target="_blank"
        >
          {{ labels.play }}
        </v-btn>
        <v-btn
          text outlined
          color="primary"
          :to="{
            name: 'game-logs',
            params: {
              envName: environment.name,
              image: item.image,
              version: item.game_backend_version,
            },
          }"
        >
          {{ labels.logs }}
        </v-btn>
        <v-btn
          text outlined
          color="primary"
          @click="showEnvironmentVariables(item)"
          :disabled="!item.consolidated"
        >
          {{ labels.details }}
        </v-btn>
      </template>
    </v-data-table>
    <v-skeleton-loader loading v-else type="table" class="flex" />

    <details-dialog
      @close="showDetailsDialog = false"
      v-if="anyWorkloadDetail"
      :dialog-items="workloadDetails"
      :name="name"
      :show="showDetailsDialog"
      :version="version"
    />
    <promotion-dialog
      :show="showPromotionsDialog"
      @close="showPromotionsDialog = false"
      @promote="promote"
      :game-to-be-promoted="gameToBePromoted"
    />

  </v-row>
</template>

<script>
/* eslint prefer-template: "off" */
import {
  find,
  get,
  isEmpty,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'gameInstanceTable',
  components: {
    detailsDialog: () => import('@/components/detailsDialog.vue'),
    promotionDialog: () => import('@/components/promotionDialog.vue'),
  },
  beforeDestroy() {
    this.clearInterval();
  },
  created() {
    // this.clear('gameInstanceCount');
    this.getData();
    this.setTimerMethod(this.getData);
    if (this.refresh) {
      this.setInterval();
    }
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'gameInstances',
      'gameInstanceCount',
      'permissions',
      'workloadDetails',
    ]),
    ...mapState('shared', [
      'loading',
      'refresh',
    ]),
    anyWorkloadDetail() {
      return !isEmpty(this.workloadDetails);
    },
    canCreatePromotions() {
      return (
        this.permissions.game_promotion_create
        && !isEmpty(this.environment.allowed_promotions)
      );
    },
  },
  data: () => ({
    desc: false,
    expandCsha: false, // value is copied to clipboard when expanded
    gameToBePromoted: null,
    headers: [
      {
        text: 'Provider',
        value: 'provider',
      },
      {
        text: 'Game',
        value: 'name',
      },
      {
        text: 'Backend version',
        value: 'game_backend_full_version',
      },
      {
        text: 'Frontend version',
        value: 'game_frontend_version',
      },
      {
        text: 'CSHA',
        value: 'csha',
        sortable: false,
      },
      {
        text: 'Logic lib',
        value: 'backend_version.gs_logiclib_version',
      },
      {
        text: 'Replicas',
        value: 'replicas',
      },
      {
        text: 'Status',
        value: 'consolidated',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    itemsPerPage: 20,
    itemsPerPageOptions: { 'items-per-page-options': [20, 50, -1] },
    labels,
    name: null,
    page: 1,
    search: null,
    sort: 'name',
    showDetailsDialog: false,
    showPromotionsDialog: false,
    version: null,
  }),
  methods: {
    ...mapActions({
      clear: 'fetch/clear',
      clearInterval: 'fetch/clearInterval',
      createPromotion: 'fetch/createPromotion',
      fetchGameInstances: 'fetch/fetchGameInstances',
      fetchWorkloadDetails: 'fetch/fetchWorkloadDetails',
      infoMsg: 'shared/infoMsg',
      setInterval: 'fetch/setInterval',
      setTimerMethod: 'fetch/setTimerMethod',
    }),
    async copyToClipboard(value) {
      if (!value) return;
      this.expandCsha = !this.expandCsha;
      if (this.expandCsha) {
        this.infoMsg(this.labels.copiedToClipboard);
        await navigator.clipboard.writeText(value);
        setTimeout(() => { this.expandCsha = false; }, 3000);
      }
    },
    getData() {
      const params = {
        environment_id: this.environment.id,
        // We prefer not to get this data paginated, since we are going to need
        // all the game instances from the Game Install table
      };
      this.fetchGameInstances({ params });
    },
    getLaunchUrl(item) {
      return (
        `${this.$maatUrl}/launch/${this.environment.name}`
        + `/${item.name.toUpperCase()}`
      );
    },
    isCompatible(version) {
      return version.startsWith(this.environment.core_version);
    },
    openPromotionsDialog(item) {
      this.gameToBePromoted = find(
        this.gameInstances,
        (gi) => gi.id === item.id,
      );
      this.showPromotionsDialog = true;
    },
    promote(body) {
      this.showPromotionsDialog = false;
      console.log(body);
      this.createPromotion({
        environmentID: this.environment.id,
        body,
      });
    },
    showEnvironmentVariables(item) {
      this.name = item.name;
      this.version = item.game_backend_version;
      const params = {
        name: `gls${item.provider}${item.name}-${item.id}`,
        namespace: `gs-games-${item.provider}`,
      };
      this.fetchWorkloadDetails({
        environmentID: this.environment.id,
        params,
      }).then(() => { this.showDetailsDialog = true; });
    },
    showLauncher(item) {
      // Show if there's a launcher or if we've an installed version+frontend
      return (
        get(this.environment, 'launcher.url')
        || !isEmpty(item.game_frontend_version)
      );
    },
  },
};
</script>

<style scoped>
  .text-truncate {
    max-width: 5vw;
  }
  .flat-btn {
    text-transform: none;
  }
</style>